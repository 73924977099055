import imgLangEs from '../assets/langs/es.png';
import imgLangEn from '../assets/langs/en.png';
import imgLangCa from '../assets/langs/cat.png';

const getLangImg = (lang) => {
    switch (lang) {
        case 'es':
            return imgLangEs;
        case 'en':
            return imgLangEn;
        case 'ca':
            return imgLangCa;
        default:
            return imgLangEs;
    }
};

export default getLangImg;
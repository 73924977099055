import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';

import Login from './Login';
import Signup from './Signup';
import ClassList from './classes/ClassList';
import ClassView from './classes/ClassView';
import User from './User';
import Awards from './Awards';

import { getLanguage } from '../language/i18n.js';
import Layout from '../components/Layout';

import { useAuth } from '../context/AuthContext';
import '../App.css';
import '@fontsource/montserrat';
import { getDataFromUser, getSchoolsData, getAwards, getInfoUser } from '../utils/APIUtils.js';
import NewClass from './classes/NewClass.js';
import LoginPin from './LoginPin.js';

function Main() {

  const [selectedClass, setSelectedClass] = useState(null);
  const [classesData, setClassesData] = useState(null);
  const [schoolsData, setSchoolsData] = useState([]);
  const [awardsData, setAwardsData] = useState([]);

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(null);

  const { user, logout, editUserAuth } = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setMessage(null);
    }, 2000);
  }, [message]);

  const logoutMain = () => {
    logout();
  }

  /** FETCH FUNCTIONS **/
  const fetchUserData = async () => {
    setLoading(true);
    const theData = await getInfoUser(user.token);

    if (theData.ok) {
      user.name = theData.name;
      user.surname = theData.surname;
      user.enabled = theData.enabled;
      user.language = theData.language;
      editUserAuth(user);
      i18n.changeLanguage(theData.language);
      if (user.enabled) {
        fetchData();
        fetchSchoolData();
        fetchAwardsData();
      }
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
  }

  const fetchData = async () => {
    setLoading(true);
    const theData = await getDataFromUser(user.token);

    if (theData.ok) {
      setClassesData(theData.data);
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
  };

  const fetchSchoolData = async () => {
    setLoading(true);
    const theData = await getSchoolsData(user.token);
    if (theData.ok) {
      setSchoolsData(theData.data);
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
  };

  const fetchAwardsData = async () => {
    setLoading(true);
    const theData = await getAwards(user.token);

    if (theData.ok) {
      setAwardsData(theData.data);
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      fetchUserData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);



  useEffect(() => {
    const lang = getLanguage();
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [i18n]);

  return (
    <Router>
      <Routes>
        {user && (
          <>
            <Route element={
              <Layout
                user={user}
                loading={loading}
                setLoading={setLoading}
                message={message}
                setMessage={setMessage}
                menu
                classesData={classesData}
                selectedClass={selectedClass}
                setSelectedClass={setSelectedClass}
                logoutMain={logoutMain}
              />
            }>
              <Route path="/classes"
                element={
                  <ClassList
                    classesData={classesData}
                    setClassesData={setClassesData}
                    user={user}
                    schoolsData={schoolsData}
                    message={message}
                    setMessage={setMessage}
                    loading={loading}
                    setLoading={setLoading}
                    selectedClass={selectedClass}
                    setSelectedClass={setSelectedClass}
                    logoutMain={logoutMain}
                  />}
              />
              <Route path="/classes/:id"
                element={<ClassView
                  awards={awardsData}
                  classesData={classesData}
                  user={user}
                  setClassesData={setClassesData}
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  logoutMain={logoutMain}
                  setSelectedClass={setSelectedClass}
                />} />
              {/*  <Route
                path="/classes/:cl_id/:st_id"
                element={<StudentBox message={message} setMessage={setMessage} />}
              /> */}
              <Route path="/newclass" element={
                <NewClass
                  user={user}
                  message={message}
                  setMessage={setMessage}
                  schools={schoolsData}
                  setSchools={setSchoolsData}
                  loading={loading}
                  setLoading={setLoading}
                  classesData={classesData}
                  setClassesData={setClassesData}
                  logoutMain={logoutMain}
                />} />
              <Route path="/user" element={
                <User
                  user={user}
                  message={message}
                  setMessage={setMessage}
                  loading={loading}
                  setLoading={setLoading}
                  logoutMain={logoutMain}
                />}
              />
              <Route path="/awards" element={
                <Awards
                  user={user}
                  message={message}
                  setMessage={setMessage}
                  awards={awardsData}
                  setAwardsData={setAwardsData}
                  loading={loading}
                  setLoading={setLoading}
                  logoutMain={logoutMain}
                />
              } />
              <Route path="*" element={<Navigate to="/classes" />} />
              {/* <Route path="*" element={<NotFound />} /> */}
            </Route>
          </>
        )}
        {!user && (
          <>
            <Route element={<Layout loading={loading} message={message} setMessage={setMessage} />}>
              <Route path="/login" element={<Login
                loading={loading}
                setLoading={setLoading}
                setMessage={setMessage}
                setClassesData={setClassesData} />} />
              <Route path="/login/:msg" element={<Login
                loading={loading}
                setLoading={setLoading}
                setMessage={setMessage}
                setClassesData={setClassesData} />} />
              <Route path="/loginpin" element={<LoginPin
                loading={loading}
                setLoading={setLoading}
                setMessage={setMessage}
                setClassesData={setClassesData} />} />
              <Route path="/signup" element={<Signup
                loading={loading}
                setLoading={setLoading}
                message={message}
                setMessage={setMessage}
                setClassesData={setClassesData}
              />} />
              <Route path="*" element={<Navigate to="/login" />} />
              {/* <Route path="*" element={<NotFound />} /> */}
            </Route>
          </>
        )}

      </Routes>
    </Router>
  );
}

export default Main;

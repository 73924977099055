import avatar1 from '../assets/avatars/avatar1.png';
import avatar2 from '../assets/avatars/avatar2.png';
import avatar3 from '../assets/avatars/avatar3.png';
import avatar4 from '../assets/avatars/avatar4.png';
import avatar5 from '../assets/avatars/avatar5.png';
import avatar6 from '../assets/avatars/avatar6.png';
import avatar7 from '../assets/avatars/avatar7.png';
import avatar8 from '../assets/avatars/avatar8.png';
import avatar9 from '../assets/avatars/avatar9.png';
import avatar_default from '../assets/avatars/avatar_default.png';

const avatars = [
    { _id: 0, img: avatar_default, title: 'avatar_default' },
    { _id: 1, img: avatar1, title: 'avatar1' },
    { _id: 2, img: avatar2, title: 'avatar2' },
    { _id: 3, img: avatar3, title: 'avatar3' },
    { _id: 4, img: avatar4, title: 'avatar4' },
    { _id: 5, img: avatar5, title: 'avatar5' },
    { _id: 6, img: avatar6, title: 'avatar6' },
    { _id: 7, img: avatar7, title: 'avatar7' },
    { _id: 8, img: avatar8, title: 'avatar8' },
    { _id: 9, img: avatar9, title: 'avatar9' },
    
]
export const getAvatarImage = (avatar) => {
    for(let av of avatars) {
        if(avatar === av.title) {
            return av.img;
        }
    }
};

export const getAvatar = (avatar) => {
    for(let av of avatars) {
        if(avatar === av.title) {
            return av;
        }
    }
}

export const getAllAvatars = () => {
    return avatars;
}
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/english.json';
import es from './translations/spanish.json';
import ca from './translations/catalan.json';

export const getLanguage = () => {
    try {
        const cachedLang = localStorage.getItem('lang');
        if (cachedLang !== null) {
            return cachedLang;
        }
    } catch (error) {
    }
};

export const saveLanguage = (lang) => {
    try {
        localStorage.setItem('lang', JSON.stringify(lang));
    } catch (error) {
        // Catch error
    }
};

i18n
    .use(initReactI18next) // Allow to use i18n in react
    .init({
        fallbackLng: 'es', // Default language
        resources: {
            en: {
                translation: en,
            },
            es: {
                translation: es,
            },
            ca: {
                translation: ca,
            }
        },
        interpolation: {
            escapeValue: false,
        },
    });
export default i18n;
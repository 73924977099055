import React from 'react';
import './Layout.css';
import { Outlet } from 'react-router-dom';
import Message from './Message';
import Loading from './Loading';
import Menu from './Menu';
import { useTranslation } from 'react-i18next';
import { sendActivationEmail } from '../utils/APIUtils';

const Layout = ({ user, loading, setLoading, message, setMessage, menu, classesData, selectedClass, setSelectedClass, logoutMain }) => {
  const { t } = useTranslation();


  const sendEnableUserEmail = async () => {
    setLoading(true);
    const theData = await sendActivationEmail(user.token);

    if (theData && theData.ok) {
      setMessage({ msg: t('email-activation-sended'), type: 'OK' });
    } else {
      setMessage({ msg: t(theData.error), type: 'ERR' });
    }

    setLoading(false);
  }

  

  return (
    <div>
      {loading && <Loading />}
      {menu && <Menu user={user} classesData={classesData} selectedClass={selectedClass} setSelectedClass={setSelectedClass} />}
      {/* MESSAGE */}
      {message && <Message message={message} setMessage={setMessage} />}
      {user && !user.enabled &&
        <>
          <h3 className='warningUserNotEnabledYet'>{t('user-not-enabled-yet')}</h3>
          <button className='btnSendActivationEmailAgain' onClick={() => sendEnableUserEmail()}>
            {t('send-activation-email')}
          </button>
        </>
      }
      <Outlet />
    </div>
  );
};

export default Layout;
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './language/i18n.js';

import Main from './pages/Main.js';

import { getLanguage } from './language/i18n.js';

import { AuthProvider } from './context/AuthContext';
import './App.css';
import '@fontsource/montserrat';

function App() {

  const { i18n } = useTranslation();

  useEffect(() => {
    const lang = getLanguage();
    if (lang) {
      i18n.changeLanguage(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthProvider>
      <Main />
    </AuthProvider>
  );
}

export default App;

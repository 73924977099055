import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
import { getFruitImage, getFruitName } from '../../utils/FruitImgUtil';
import { getStringDate } from '../../utils/DateUtils';


import './StudentView.css';
import { getAllAvatars, getAvatarImage } from '../../utils/AvatarUtil.js';
import { editStudentFromClass, removeStudentFromClass } from '../../utils/APIUtils.js';
import Modal from '../../components/Modal.js';

function StudentView({ setView, viewClass, setClassesData, classesData, user, theClass, logoutMain, setMessage, student, setLoading }) {

  const { t } = useTranslation();

  const [showModalDeleteStudent, setShowModalDeleteStudent] = useState(false);
  const [showModalEditStudent, setShowModalEditStudent] = useState(false);

  const [editingAvatar, setEditingAvatar] = useState(false);
  const [listFruits, setListFruits] = useState([]);
  const [listAwards, setListAwards] = useState([]);

  const [selectedAvatar, setSelectedAvatar] = useState(student.avatar)

  const avatars = getAllAvatars();

  const onClickAvatar = () => {
    if (theClass.user_id === user._id) {
      setEditingAvatar(true)
    }
  }

  // When click on button delete student
  const onClickDeleteStudent = () => {
    setShowModalDeleteStudent(true);
  }

  // When click on button edit student
  const onClickEditStudent = () => {
    setShowModalEditStudent(true);
  }

  // If user press ok in delete user modal, then remove student from class
  const removeStudent = async () => {
    setLoading(true);

    const theData = await removeStudentFromClass(user.token, theClass._id, student._id);

    if (theData && theData.ok) {
      let newClasses = classesData.filter((c) => c._id !== theClass._id);
      newClasses.push(theData.data);
      setClassesData(newClasses);
      setView(viewClass);
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }

    }

    setLoading(false);
    setShowModalDeleteStudent(false);
  }

  // When user click on cancel in delete student modal
  const cancelRemoveStudent = () => {
    setShowModalDeleteStudent(false);
  }


  // If user press ok in edit student modal, then edit student
  const editStudent = async (params) => {
    setLoading(true);

    const theData = await editStudentFromClass(user.token, theClass._id, student._id, params.name, student.avatar);

    if (theData && theData.ok) {
      let newClasses = classesData.filter((c) => c._id !== theClass._id);
      newClasses.push(theData.data);
      setClassesData(newClasses);
      student.name = params.name;
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }

    setLoading(false);
    setShowModalEditStudent(false);
  }

  // When user click on cancel in edit student modal
  const cancelEditStudent = () => {
    setShowModalEditStudent(false);
  }



  const updateAvatar = async () => {
    setLoading(true);

    const theData = await editStudentFromClass(user.token, theClass._id, student._id, student.name, selectedAvatar);

    if (theData && theData.ok) {
      let newClasses = classesData.filter((c) => c._id !== theClass._id);
      newClasses.push(theData.data);
      setClassesData(newClasses);
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
    setEditingAvatar(false);
  }

  useEffect(() => {
    if (student) {

      //SET TOTAL LIST FRUIT
      let fruitTotalL = [];
      for (const m of student.meals) {
        let fruit = fruitTotalL.find(fr => fr.fruit === m.fruit);
        if (!fruit) {
          fruit = {
            'fruit': m.fruit,
            'name': t(getFruitName(m.fruit)),
            'img': getFruitImage(m.fruit),
            'total': 0
          };
          fruitTotalL.push(fruit);
        }
        fruit.total += m.points;
      }
      fruitTotalL = fruitTotalL.sort((a, b) => b.total - a.total);
      setListFruits(fruitTotalL);

      //SET TOTAL LIST AWARDS
      setListAwards(student.awards);
    }
  }, [student, t]);

  return (
    <div className='divStudentViewGeneral'>
      <div>
        {/******* MODAL DELETE STUDENT *******/}
        {showModalDeleteStudent && <Modal
          message={t('modal-sure-remove-student', { studentName: (student ? student.name : '-') })}
          msgOk={t('modal-btn-delete-ok')}
          msgCancel={t('modal-btn-delete-cancel')}
          agreeFunction={removeStudent}
          cancelFunction={cancelRemoveStudent} />}

        {/******* MODAL EDIT STUDENT *******/}
        {showModalEditStudent && <Modal
          message={t('modal-edit-student')}
          msgOk={t('modal-btn-edit-ok')}
          msgCancel={t('modal-btn-edit-cancel')}
          agreeFunction={editStudent}
          cancelFunction={cancelEditStudent}
          listFields={[
            { name: 'name', type: 'text', placeholder: t('name'), mandatory: true, value: (student ? student.name : '-') },
          ]}
        />}
      </div>
      {editingAvatar &&
        <div>
          <button className='btnUpdateAvatar' onClick={updateAvatar}>{t('update-avatar')}</button>
          <div className='divStudentViewAvatarList'>
            {
              avatars.map((av) => (
                <img
                  className={`imgAvatarList ${selectedAvatar === av.title && 'imgAvatarListSelected'}`}
                  src={av.img}
                  key={av._id}
                  alt="avatarImg"
                  onClick={() => setSelectedAvatar(av.title)}
                />
              ))
            }
          </div>
        </div>
      }
      {!editingAvatar &&
        <div className='divStudentView'>
          <div className='divStudentViewFirstRow'>
            <img className='imgStudentViewAvatar' src={getAvatarImage(selectedAvatar)} alt="avatar"
              onClick={() => onClickAvatar()} />
            <div>
              <h1 className="studentNameStudentView">{student.name}</h1>
              <div className='divStudentViewPoints'>
                <div>
                  <h3>{student.meals ? student.meals.reduce((sum, meal) => sum + meal.points, 0) : 0}</h3>
                  <p>{t('total-points')}</p>
                </div>
                <div>
                  <h3>{student.awards && student.meals ? (student.meals.reduce((sum, meal) => sum + meal.points, 0) - student.awards.reduce((sum, aw) => sum + aw.points, 0)) : 0}</h3>
                  <p>{t('current-points')}</p>
                </div>
              </div>
              {theClass.user_id === user._id &&
                <div className='divStudentViewOptions'>
                  <button onClick={onClickEditStudent}>{t('edit-student')}</button>
                  <button onClick={onClickDeleteStudent}>{t('delete-student')}</button>
                </div>
              }
            </div>
          </div>
          <div className='divStudentViewFruits'>
            {listFruits && listFruits.map((lf) => (
              <div className='divStudentViewFruit' key={lf.fruit}>
                <img src={lf.img} alt={"Fruit:" + lf.fruit} />
                <p>{lf.total}</p>
              </div>
            ))}
          </div>

          {listAwards && listAwards.length > 0 &&
            <>
              <h1>{t('awards')}</h1>
              <div className='divStudentViewAwards'>
                {listAwards && listAwards.map((aw) => (
                  <div className='divStudentViewAward' key={aw._id}>
                    <h2>{aw.title}</h2>
                    <p>{getStringDate(aw.date)}</p>
                    <p>{aw.points + ' ' + t('points')}</p>
                  </div>
                ))}
              </div>
            </>
          }
        </div>
      }
    </div>
  );
};

export default StudentView;

import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import './ClassView.css';

import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
import { getAllFruitImage } from '../../utils/FruitImgUtil';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faAward, faChartBar, faCog, faPlus, faSchool, faUserGraduate } from '@fortawesome/free-solid-svg-icons';

import { addAwardToStudent, addStudentsToClass, removeMealFromUser } from '../../utils/APIUtils.js';
import Calendar from '../../components/Calendar';
import Modal from '../../components/Modal';
import NewStudents from './NewStudents';
import StudentBox from '../students/StudentBox';
import StatsView from '../stats/StatsView';
import { getMySQLDateFromJSDate } from '../../utils/DateUtils';
import StudentView from '../students/StudentView';
import SetupClass from './SetupClass';


function ClassView({ setSelectedClass, classesData, user, setClassesData, message, setMessage, loading, setLoading, awards, logoutMain }) {
  const { t } = useTranslation();

  const { id } = useParams();

  const viewClass = 'C';
  const viewAward = 'A';
  const viewStats = 'S';
  const viewStudent = 'ST';
  const viewAddStudents = 'AS';
  const viewSetup = 'SC';

  const [view, setView] = useState(viewClass);

  const theClass = classesData.filter(cl => cl._id === id).length === 1 ? classesData.filter(cl => cl._id === id)[0] : null;

  const [date, setDate] = useState(new Date());

  const [studentList, setStudentList] = useState([
    { name: '' }
  ]);

  // Selected objects to use for delete, update and insert elements
  const [selectedFruit, setSelectedFruit] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const [selectedMeal, setSelectedMeal] = useState(null);

  const [selectedAward, setSelectedAward] = useState(null);

  // Modals
  const [showModalDeleteMeal, setShowModalDeleteMeal] = useState(false);
  const [showModalAddAward, setShowModalAddAward] = useState(false);

  // Get student list to recalculate height to do not go beyond the screen
  const divOverflowRef = useRef(null);
  // Use state to save the size of the student list div
  const [divOverflowHeight, setDivOverflowHeight] = useState(100);

  useEffect(() => {
    setView(viewClass);
    setSelectedStudent(null);
  }, [theClass._id]);
  // Calculate the height that must have student list has to do not go beyond the screen
  useEffect(() => {

    const recalculateHeight = () => {
      if (divOverflowRef.current) {
        const windowHeight = window.innerHeight;
        const elementTop = divOverflowRef.current.getBoundingClientRect().top;
        const calculatedHeight = windowHeight - elementTop;
        setDivOverflowHeight(calculatedHeight);
      }
    };

    // Recalculate student list height at the beginning
    recalculateHeight();
    // When window size change recalculate
    window.addEventListener('resize', recalculateHeight);
    // Remove listener when unmount component
    return () => {
      window.removeEventListener('resize', recalculateHeight);
    };
  })

  // When you click on a fruit you want to add into student
  const onClickFruit = (fruit) => {
    if (fruit && selectedFruit && fruit._id === selectedFruit._id) {
      setSelectedFruit(null);
    } else {
      setSelectedFruit(fruit)
    }
  }

  // When you click on award
  const onClickAward = (award) => {
    if (award === selectedAward) {
      setSelectedAward(null);
    } else {
      setSelectedAward(award);
    }
  }


  // When user have filled the names of students in modal and press add students to class
  const clickAddStudentsToClass = async () => {
    if (studentList && studentList.length > 1) {
      let theList = [];

      theList = studentList.filter((s) => s.name !== '');

      setLoading(true);
      const theData = await addStudentsToClass(user.token, theClass._id, theList);

      if (theData && theData.ok) {
        classesData = [...classesData];
        const updatedClass = classesData.filter((c) => c._id === theClass._id);
        if (updatedClass && updatedClass[0]) {
          updatedClass[0].students = theData.data.students;
        }
        setClassesData(classesData);
      } else {
        if (theData && theData.error && theData.error === 'invalid-token') {
          logoutMain();
        } else {
          setMessage({ msg: t(theData.error), type: 'ERR' });
        }
      }

      setLoading(false);
      setView(viewClass);
      setStudentList([{ name: '' }]);

    }

  }

  // Get fruits and images
  const fruits = getAllFruitImage();

  // When drag fruit, set selected fruit
  const onDragStart = (event, fr) => {
    setSelectedFruit(fr);
  }


  // When user click on ok in add award modal
  const addAwardToStudentModal = async () => {
    setLoading(true);
    const theData = await addAwardToStudent(user.token, theClass._id, getMySQLDateFromJSDate(date), selectedStudent._id, selectedAward._id, selectedAward.title, selectedAward.points);

    if (theData && theData.ok) {

      classesData = [...classesData.filter((c) => c._id !== theClass._id)];
      classesData.push(theData.data);
      setClassesData(classesData);
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
    setShowModalAddAward(false);
  }

  // When user click on cancel in add award modal
  const cancelAddAward = () => {
    setShowModalAddAward(false);
  }

  // When user click ok in modal delete meal
  const onClickOkModalDeleteMeal = () => {
    deleteMeal(selectedStudent, selectedMeal);
  }

  // When user click on cancel in delete meal modal
  const cancelRemoveMeal = () => {
    setShowModalDeleteMeal(false);
  }

  // When user click on fruit when computer mode or
  // click ok in modal in mobile mode
  const deleteMeal = async (student, meal) => {
    setLoading(true);

    const theData = await removeMealFromUser(user.token, theClass._id, student._id, meal._id);

    if (theData && theData.ok) {
      const mealToDelete = document.getElementById('meal' + meal._id);
      mealToDelete.classList.add('divMeal-remove');
      setTimeout(() => {
        classesData = [...classesData.filter((c) => c._id !== theClass._id)];
        classesData.push(theData.data);
        setClassesData(classesData);
      }, 500);
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
    setShowModalDeleteMeal(false);
    setSelectedStudent(null);
    setSelectedMeal(null);
  }

  const setShowViewStudent = () => {
    setView(viewStudent);
  }

  return (
    <div className='divClassView'>

      {/******* MODAL DELETE MEAL *******/}
      {showModalDeleteMeal && <Modal
        message={t('modal-sure-remove-meal')}
        msgOk={t('modal-btn-delete-ok')}
        msgCancel={t('modal-btn-delete-cancel')}
        agreeFunction={onClickOkModalDeleteMeal}
        cancelFunction={cancelRemoveMeal} />}

      {/******* MODAL ADD AWARD *******/}
      {showModalAddAward && <Modal
        message={t('modal-sure-add-award', { awardTitle: (selectedAward ? selectedAward.title : '-'), studentName: (selectedStudent ? selectedStudent.name : '-') })}
        msgOk={t('modal-btn-add-award-ok')}
        msgCancel={t('modal-btn-add-award-cancel')}
        agreeFunction={addAwardToStudentModal}
        cancelFunction={cancelAddAward} />}

      <div className='divClassViewFirstRow'>
        <div className='divClassViewFirstRowFirstCol'>

          {/* VIEW CLASS  BTN */}
          <div onClick={() => setView(viewClass)} className={`iconOperation ${view === viewClass && 'iconOperationSelected'}`}>
            <FontAwesomeIcon icon={faSchool} />
          </div>
          {/* VIEW AWARDS  BTN */}
          {theClass.user_id === user._id &&
            <div onClick={() => setView(viewAward)} className={`iconOperation ${view === viewAward && 'iconOperationSelected'}`}>
              <FontAwesomeIcon icon={faAward} />
            </div>
          }
          {/* VIEW STATS  BTN */}
          <div onClick={() => setView(viewStats)} className={`iconOperation ${view === viewStats && 'iconOperationSelected'}`}>
            <FontAwesomeIcon icon={faChartBar} />
          </div>
          {/* ADD STUDENT BTN */}
          {theClass.user_id === user._id &&
            <div onClick={() => setView(viewAddStudents)} className={`iconOperation ${view === viewAddStudents && 'iconOperationSelected'}`}>
              <FontAwesomeIcon icon={faPlus} />
              <FontAwesomeIcon icon={faUserGraduate} />
            </div>
          }
          {/* VIEW SETUP CLASS BTN */}
          {theClass.user_id === user._id &&
            <div onClick={() => setView(viewSetup)} className={`iconOperation ${view === viewSetup && 'iconOperationSelected'}`}>
              <FontAwesomeIcon icon={faCog} />
            </div>
          }
        </div>

        {(view === viewClass || view === viewStats) && <div>
          {/* CALENDAR */}
          <Calendar date={date} setDate={setDate} />
        </div>}
      </div>


      {/* FRUIT PICKER */}
      {view === viewClass && <div className="divPickFruit">
        {/*<div className='divFruits'>*/}
        {
          fruits && fruits.map((fr) => (
            <div className='divPickFruitImg' key={fr._id}>
              <img src={fr.img}
                className={selectedFruit && (selectedFruit._id === fr._id) ? 'imgFruitPicker selectedFruit' : 'imgFruitPicker'}
                draggable
                onDragStart={(event) => onDragStart(event, fr)}
                onClick={() => onClickFruit(fr)}
                style={{ title: { color: "red" } }}
                alt={"fruit"}
              />
            </div>
          ))
        }
      </div>
      }



      {/* AWARD PICKER */}
      {view === viewAward && <div className="divPickAward">
        {
          awards && awards.sort((aw1, aw2) => aw1['points'] - aw2['points']).map((aw) => (
            <div onClick={() => onClickAward(aw)}
              className={`divAward ${selectedAward === aw && 'divAwardSelected'}`}
              key={aw._id}>
              <FontAwesomeIcon icon={faAward} className='awardIcon'/>
              <h3 className='awardTitle'>{aw.default ? t(aw.title) : aw.title}</h3>
              <h3 className='awardPoints'>{aw.points}</h3>
            </div>
          ))
        } 
        
        {
          <Link to="/awards">
            <FontAwesomeIcon icon={faAdd} className='addAwardIcon'/>
          </Link>
        }
      </div>
      }


      <div ref={divOverflowRef} className="divOverflow"
        style={{ height: `${divOverflowHeight}px`, }}>

        { /***** ADD STUDENTS VIEW ******/}
        {view === viewAddStudents &&
          <div className='divViewAddStudents'>
            <NewStudents
              studentList={studentList}
              setStudentList={setStudentList}
            />
            <button onClick={clickAddStudentsToClass}>{t('add-students-btn')}</button>
          </div>
        }

        { /***** STUDENT VIEW ******/}
        {view === viewStudent &&
          <StudentView 
          student={selectedStudent} 
          setView={setView} 
          viewClass={viewClass}
          setLoading={setLoading}
          setSelectedStudent={setSelectedStudent}
          user={user}
          theClass={theClass}
          logoutMain={logoutMain}
          setMessage={setMessage}
          classesData={classesData}
          setClassesData={setClassesData}
          />
        }

        {/****** STUDENT LIST ******/}
        {(view === viewClass || view === viewAward)
          &&
          (!theClass.students || theClass.students.length === 0)
          &&
          <h2 className='h2NoStudents'>{t('no-students')}</h2>}

        {(view === viewClass || view === viewAward) && theClass && theClass.students.sort((a, b) => a.name.localeCompare(b.name)).map((st) => (
          <StudentBox
            key={st._id}
            date={date}
            student={st}
            setSelectedStudent={setSelectedStudent}
            loading={loading}
            setLoading={setLoading}
            message={message}
            setMessage={setMessage}
            theClass={theClass}
            classesData={classesData}
            setClassesData={setClassesData}
            selectedAward={selectedAward}
            setSelectedAward={setSelectedAward}
            selectedFruit={selectedFruit}
            setSelectedFruit={setSelectedFruit}
            user={user}
            setShowViewStudent={setShowViewStudent}
            deleteMeal={deleteMeal}
            setSelectedMeal={setSelectedMeal}
            setShowModalAddAward={setShowModalAddAward}
            setShowModalDeleteMeal={setShowModalDeleteMeal}
            classView={view === viewClass}
            awardsView={view === viewAward}
            logoutMain={logoutMain}
          />
        ))}


        {/****** STATS VIEW ******/}
        {
          (view === viewStats) &&
          <StatsView
            date={date}
            theClass={theClass} />
        }

        {/****** SETUP CLASS VIEW ******/}
        {
          (view === viewSetup) &&
          <SetupClass
            theClass={theClass} 
            setClassesData={setClassesData}
            classesData={classesData}
            setMessage={setMessage}
            setLoading={setLoading}
            user={user}
            logoutMain={logoutMain}
            setSelectedClass={setSelectedClass}
            />
        }

      </div>


    </div >
  );
};

export default ClassView;

export const getMySQLDateFromJSDate = (jsdate) => {
    let day = jsdate.getDate();
    day = day < 10 ? '0' + day : day;
    let month = jsdate.getMonth() +1 ;
    month = month < 10 ? '0' + month : month;
    let year = jsdate.getFullYear();
    return year + '-' + month + '-' + day;
};

export const getStringDate = (jsdate) => {
    jsdate = new Date(jsdate);
    let day = jsdate.getDate();
    day = day < 10 ? '0' + day : day;
    let month = jsdate.getMonth() +1 ;
    month = month < 10 ? '0' + month : month;
    let year = jsdate.getFullYear();
    return day + '/' + month + '/' + year;
}

//const IP = 'http://192.168.5.39:3001';
//const IP = 'http://localhost:3001';
const IP = process.env.REACT_APP_BACKEND_URL;
//'https://backend.fruitity.com';
//const IP = 'http://192.168.215.39:3001';
//const IP = 'http://10.2.0.190:3001';

export const getQuery = async (route, params) => {
  try {
    const response = await fetch(IP + route, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if(data.hasOwnProperty('ok')) {
      return data;
    } else { 
      return {
        ok: false,
        error: data.error
      }
    }
  } catch (error) {
    return {
      ok: false,
      error: 'net-error'
    }
  }
}

export const getInfoUser = async (token) => {
  return await getQuery('/userinfo', {token});
}

export const getDataFromUser = async (token) => {
  return await getQuery('/classes', {token});
  
};

export const getSchoolsData  = async (token) => {
  return await getQuery('/schools', {token});
};

export const getAwards = async(token) => {
  return await getQuery('/awards', {token});
}

export const getUserLogin = async (email, password) => {
  return await getQuery('/login', {email, password});
};

export const sendPinToUser = async (email) => {
  return await getQuery('/sendpintouser', {email});
}

export const loginUserWithPin = async (email, pin) => {
  return await getQuery('/loginpin', {email, pin});
}

export const getUserLoginGoogle = async (userGoogleAccessToken, userGoogleName, userGoogleEmail) => {
  return await getQuery('/logingoogle', {userGoogleAccessToken, userGoogleName, userGoogleEmail});
};

export const getUserSignup = async (name, surname, email, password, language) => {
  return await getQuery('/signup', {name, surname, email, password, language});
}

export const sendActivationEmail = async(token) => {
  return await getQuery('/sendactivationemail', {token});
}


export const editUser = async (token, name, surname, isNewPassword, newPassword, language) => {
  return await getQuery('/edituser', {token, name, surname, isNewPassword, newPassword, language});
};

export const addMealToUser = async (token, classId, date, studentId, fruitId, points) => {
  return await getQuery('/addmeal', { token, classId, date, studentId, fruitId, points});
};


export const addAwardToStudent = async (token, classId, date, studentId, awardId, awardTitle, points) => {
  return await getQuery('/addawardtostudent', { token, classId, date, studentId, awardId, awardTitle, points});
};

export const addAward = async (token, awardName, awardPoints) => {
  return await getQuery('/addaward', { token, awardName, awardPoints});
};

export const addClass = async (token, schoolName, className, studentList) => {
  return await getQuery('/addclass', { token, schoolName, className, studentList});
};


export const addSchool = async (token, name, city) => {
  return await getQuery('/addschool',{ token, name, city});
};

export const addStudentsToClass = async (token, classId, list) => {
  return await getQuery('/addstudents',{ token, classId, list});
}

export const inviteTeacherToClass = async (token, classId, email) => {
  return await getQuery('/inviteteacher',{ token, classId, email});
}

export const removeInvitationTeacherToClass = async (token, classId, email) => {
  return await getQuery('/removeinviteteacher',{ token, classId, email});
}

export const removeClass = async(token, classId) => {
  return await getQuery('/removeclass',{ token, classId});
}

export const editClass = async(token, classId, className, schoolName) => {
  return await getQuery('/editclass',{ token, classId, className, schoolName});
}

export const editAward = async(token, awardId, awardTitle, awardPoints) => {
  return await getQuery('/editaward',{ token, awardId, awardTitle, awardPoints});
}

export const removeAward = async(token, awardId) => {
  return await getQuery('/removeaward',{ token, awardId});
}

export const removeStudentFromClass = async(token, classId, studentId) => {
  return await getQuery('/removestudent',{ token, classId, studentId});
}

export const editStudentFromClass = async(token, classId, studentId, studentName, avatar) => {
  return await getQuery('/editstudent',{ token, classId, studentId, studentName, avatar});
}

export const removeMealFromUser = async (token, classId, studentId, mealId) => {
  return await getQuery('/removemeal',{ token, classId, studentId, mealId});
};

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import './LoginPin.css';
import fruitityIcon from '../assets/fruitity-icon.png';

import { getAllFruitImage } from '../utils/FruitImgUtil.js';
import { sendPinToUser } from '../utils/APIUtils.js';
import getLangImg from '../utils/LangUtil.js';

function LoginPin({ loading, setLoading, setMessage, setClassesData }) {
    const [email, setEmail] = useState('');
    const [pin, setPin] = useState('');
    const [language, setLanguage] = useState('');

    const [errorLoginPin, setErrorLoginPin] = useState(null);

    const [fruitsPositions, setFruitsPositions] = useState([]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const { loginPin } = useAuth();

    const sendPin = async () => {
        setLoading(true);
        const theData = await sendPinToUser(email);
        if (theData.ok) {
            setMessage({ msg: t('pin-sended-properly'), type: 'OK' });
        } else {
            setErrorLoginPin(t(theData.error));
            //setMessage({ msg: t(theData.error), type: 'ERR' });
        }
        setLoading(false);
    };

    const handleLoginPin = async () => {
        setLoading(true);
        const theData = await loginPin(email, pin);
        if (theData.ok) {
            //Set classes
            setClassesData([]);
            //Set language
            let lang = theData.data.language ? theData.data.language : 'es';
            i18n.changeLanguage(lang);
            localStorage.setItem('lang', JSON.stringify(lang));
            //Navigate
            navigate('/classes');
        } else {
            setErrorLoginPin(t(theData.error));
            //setMessage({ msg: t(theData.error), type: 'ERR' });
        }
        setLoading(false);
    };

    const onClickLang = (lang) => {

        i18n.changeLanguage(lang);
        localStorage.setItem('lang', JSON.stringify(lang));
        setLanguage(lang);
    }

    useEffect(() => {
        const fruits = getAllFruitImage();
        let fruitsPos = [];
        let width = window.innerWidth - 100;
        let height = window.innerHeight - 100;
        for (let fr of fruits) {
            let top = Math.floor(Math.random() * (height - 0 + 1) + 0);
            let left = Math.floor(Math.random() * (width - 0 + 1) + 0);

            fruitsPos.push({ id: fr._id, img: fr.img, top: top + 'px', left: left + 'px' });
        }
        setFruitsPositions(fruitsPos);

        let localStorageLang = localStorage.getItem('lang');

        const changeLanguage = async (language) => {
            await i18n.changeLanguage(language);
            localStorage.setItem('lang', JSON.stringify(language));
        }
        if (localStorageLang) {
            localStorageLang = JSON.parse(localStorageLang);
            changeLanguage(localStorageLang);
            onClickLang(localStorageLang);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div className='loginPinDivOutside'>
            <div className='loginPinDivBackground'>
                <div className='loginPinDivBackgroundOutside'>
                    {fruitsPositions.map((fr) => (
                        <img alt="frpos" width={'100px'} height={'100px'} src={fr.img} key={fr.id} style={{ top: fr.top, left: fr.left }} />
                    ))}
                </div>
            </div>
            <div className='loginPinDivInside'>
                <div className='loginPinDivImg'>
                    <img alt="fruitityicon" className="loginPinImg" src={fruitityIcon} />
                    <h1 className='menuTitle'>Fruitity</h1>
                </div>
                <div className='loginPinDivForm'>
                    <h1 className='loginPinTitle'>{t('login-pin')}</h1>
                    <div className="loginPinForm">
                        <div className="loginPinInput">
                            <input type="text" id="email" name="email" value={email} placeholder={t('email')} onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor="email">{t('email')}</label>
                        </div>
                        <div className="loginPinInput">
                            <input type="number" id="pin" name="pin" value={pin} placeholder={t('pin')} onChange={(e) => setPin(e.target.value)} />
                            <label htmlFor="pin">{t('pin')}</label>
                        </div>
                        <button onClick={sendPin}>{t('send-pin')}</button>
                        <button onClick={handleLoginPin}>{t('login-pin')}</button>
                        <Link to="/login">
                            <p className='loginUserPassword'>{t('login-email-password')}</p>
                        </Link>
                    </div>
                    <div>
                        <img alt="es" onClick={() => onClickLang('es')} className={`imgUserLang ${language === 'es' && 'imgUserLangSelected'}`} src={getLangImg('es')} />
                        <img alt="ca" onClick={() => onClickLang('ca')} className={`imgUserLang ${language === 'ca' && 'imgUserLangSelected'}`} src={getLangImg('ca')} />
                        <img alt="en" onClick={() => onClickLang('en')} className={`imgUserLang ${language === 'en' && 'imgUserLangSelected'}`} src={getLangImg('en')} />
                    </div>
                    {errorLoginPin &&
                        <p className="loginPinError">{errorLoginPin}</p>}
                </div>
            </div>
        </div>
    );
};

export default LoginPin;

import {  faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal';
import { editClass, inviteTeacherToClass, removeClass, removeInvitationTeacherToClass } from '../../utils/APIUtils';
import './SetupClass.css';

function SetupClass({ setSelectedClass, theClass, setClassesData, classesData, setMessage, setLoading, user, logoutMain }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [showModalDeleteClass, setShowModalDeleteClass] = useState(false);
  const [showModalEditClass, setShowModalEditClass] = useState(false);
  const [showModalInviteTeacher, setShowModalInviteTeacher] = useState(false);
  const [showModalDeleteInvitation, setShowModalDeleteInvitation] = useState(false);

  const [selectedEmail, setSelectedEmail] = useState(null);


  /** EDIT CLASS **/
  const onClickEditClass = () => {
    setShowModalEditClass(true);
  }

  const editClassFromUser = async (params) => {
    setLoading(true);

    const theData = await editClass(user.token, theClass._id, params.name, params.school);

    if (theData && theData.ok) {
      let newClasses = [...classesData];
      for (let c of newClasses) {
        if (c._id === theClass._id) {
          c.name = theData.data.name;
          c.school_name = theData.data.school_name;
        }
      }
      setClassesData(newClasses);
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }

    setLoading(false);
    setShowModalEditClass(false);
  }

  const cancelEditClass = () => {
    setShowModalEditClass(false);
  }

  /** DELETE CLASS **/
  const onClickDeleteClass = () => {
    setShowModalDeleteClass(true);
  }

  const deleteClassFromUser = async () => {
    setLoading(true);

    const theData = await removeClass(user.token, theClass._id);

    if (theData && theData.ok) {
      setSelectedClass(null);
      classesData = [...classesData.filter((c) => c._id !== theClass._id)];
      setClassesData(classesData);
      navigate('/classes');
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }

    setLoading(false);
    setShowModalDeleteClass(false);
  }

  const cancelRemoveClass = () => {
    setShowModalDeleteClass(false);
  }

  /** INVITE TEACHER **/
  const onClickInviteTeacher = async () => {
    setShowModalInviteTeacher(true);
  }

  const inviteTeacher = async (params) => {
    setLoading(true);

    if (params.email === user.email) {
      setMessage({ msg: t('cannot-invite-yourself'), type: 'ERR' });
    } else {
      const theData = await inviteTeacherToClass(user.token, theClass._id, params.email);

      if (theData && theData.ok) {
        let newClasses = [...classesData];
        for (let c of newClasses) {
          if (c._id === theClass._id) {
            let usersInvited = [].concat(c.usersInvited ? c.usersInvited : []);
            usersInvited.push(params.email);
            c.usersInvited = usersInvited;
          }
        }
        setClassesData(newClasses);
        setMessage({ msg: t('teacher-invited'), type: 'OK' });
      } else {
        if (theData && theData.error && theData.error === 'invalid-token') {
          logoutMain();
        } else {
          setMessage({ msg: t(theData.error), type: 'ERR' });
        }
      }
    }

    setLoading(false);
    setShowModalInviteTeacher(false);
  }

  const cancelInviteTeacher = async () => {
    setShowModalInviteTeacher(false);
  }

  /** DELETE INVITATION **/
  const onClickDeleteInvitation = (email) => {
    setSelectedEmail(email);
    setShowModalDeleteInvitation(true);
  }

  const deleteInvitation = async (params) => {
    setLoading(true);

    const theData = await removeInvitationTeacherToClass(user.token, theClass._id, selectedEmail);

    if (theData && theData.ok) {
      let newClasses = [...classesData];
      for (let c of newClasses) {
        if (c._id === theClass._id) {
          let usersInvited = c.usersInvited.filter((u) => u !== selectedEmail);
          c.usersInvited = usersInvited;
        }
      }
      setClassesData(newClasses);
      setMessage({ msg: t('teacher-uninvited'), type: 'OK' });
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setSelectedEmail(null);
    setLoading(false);
    setShowModalDeleteInvitation(false);
  }

  const cancelDeleteInvitation = () => {
    setSelectedEmail(null);
    setShowModalDeleteInvitation(false);
  }


  return (
    <div>
      <div className='divSetupClassModals'>
        {showModalDeleteClass && <Modal
          message={t('modal-sure-remove-class', { className: (theClass ? (theClass.name) : '-') })}
          msgOk={t('modal-btn-delete-ok')}
          msgCancel={t('modal-btn-delete-cancel')}
          agreeFunction={deleteClassFromUser}
          cancelFunction={cancelRemoveClass} />}

        {showModalEditClass && <Modal
          message={t('modal-edit-class')}
          msgOk={t('modal-btn-edit-ok')}
          msgCancel={t('modal-btn-edit-cancel')}
          agreeFunction={editClassFromUser}
          cancelFunction={cancelEditClass}
          listFields={[
            { name: 'name', type: 'text', placeholder: t('classname'), mandatory: true, value: theClass.name },
            { name: 'school', type: 'text', placeholder: t('schoolname'), mandatory: true, value: theClass.school_name },
          ]}
        />}

        {showModalInviteTeacher && <Modal
          message={t('modal-invite-teacher')}
          msgOk={t('modal-btn-invite-teacher-ok')}
          msgCancel={t('modal-btn-invite-teacher-cancel')}
          agreeFunction={inviteTeacher}
          cancelFunction={cancelInviteTeacher}
          listFields={[
            { name: 'email', type: 'email', placeholder: t('email'), mandatory: true },
          ]}
        />}

        {showModalDeleteInvitation && <Modal
          message={t('modal-sure-remove-invitation', { email: selectedEmail })}
          msgOk={t('modal-btn-delete-ok')}
          msgCancel={t('modal-btn-delete-cancel')}
          agreeFunction={deleteInvitation}
          cancelFunction={cancelDeleteInvitation} />}
      </div>
      <div className='divSetupClass'>
        <h1>{t('setup-class')}</h1>
        <div className='divSetupClassName'>
          <h2>{t('classname')}</h2>
          <h2>{theClass && theClass.name}</h2>
          <h2>{t('schoolname')}</h2>
          <h2>{theClass && theClass.school_name}</h2>
        </div>
        <button className='btnSetupClassEditClass' onClick={() => onClickEditClass()}>{t('edit-class')}</button>
        <hr></hr>
        <div className='divSetupClassUsersInvited'>
          <h2>{t('users-invited')}</h2>
          {theClass && theClass.usersInvited && theClass.usersInvited.map((userInvited) => (
            <div className='divSetupClassUserInvited' key={"uI" + userInvited}>
              <p>{userInvited}</p>
              <FontAwesomeIcon onClick={() => onClickDeleteInvitation(userInvited)} className='iconDeleteInvitation' icon={faTrash} />
            </div>
          ))}
          <p className='divSetupClassUsersInvitedPInvite'>{t('invite-user-p')}</p>
          <button onClick={() => onClickInviteTeacher()}>{t('invite-user-btn')}</button>
        </div>
        <hr></hr>
        <div>
          <h2>{t('delete-class')}</h2>
          <button onClick={() => onClickDeleteClass()} className='btnSetupClassDeleteClass'>{t('delete-class-btn')}</button>
        </div>
      </div>
    </div>
  );
};

export default SetupClass;

import React from 'react';
import { useTranslation } from 'react-i18next';
import './NewStudents.css';

function NewStudents({ studentList, setStudentList }) {
  const { t } = useTranslation();

  const handleKeyDown = (event, index) => {
    if (event.keyCode === 13) {
      event.preventDefault(); // Avoid to send form if it is necessary
      
      let nextId = index + 1;
      let nextElement = document.getElementById('inp_student' + nextId);
      if(nextElement) {
        nextElement.focus();
      }
    }
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedStudentList = [...studentList];
    updatedStudentList[index][name] = value;
    if (studentList[index].name &&
      studentList[studentList.length - 1].name) {
      updatedStudentList.push({ name: ''})
    }
    setStudentList(updatedStudentList);
  };

  return (
        <div className='divNewClassStudents'>
          <h3>{t('new-class-create-students')}</h3>
          {studentList.map((student, index) => (
            <div className='divNewClassStudent' key={index}>
              <label>{t('student') + ' ' + (index + 1)}: </label>
              <input
                type="text"
                name="name"
                id={"inp_student" + index}
                index={index}
                placeholder={t('name')}
                value={student.name}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            </div>
          ))}
        </div>
  );
};

export default NewStudents;

import React, { useState, useEffect } from 'react';

import Podium from '../../components/Podium.js';
import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';

import './StatsView.css';

import { getFruitName } from '../../utils/FruitImgUtil.js';


function StatsView({ date, theClass }) {

  const { t } = useTranslation();

  const [studentWeekList, setStudentWeekList] = useState([]);
  const [studentMonthList, setStudentMonthList] = useState([]);
  const [studentTotalList, setStudentTotalList] = useState([]);

  const [fruitWeekList, setFruitWeekList] = useState([]);
  const [fruitMonthList, setFruitMonthList] = useState([]);
  const [fruitTotalList, setFruitTotalList] = useState([]);

  function getWeekStart(date) {
    const day = date.getDay();
    const mondayOffset = day === 0 ? 6 : day - 1;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - mondayOffset);
  }

  function getWeekEnd(date) {
    const startDate = getWeekStart(date);
    return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 6);
  }

  function getMonthStart(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  function getMonthEnd(date) {
    const month = date.getMonth();
    const year = date.getFullYear();
    const nextMonth = month === 11 ? 0 : month + 1;
    const nextYear = month === 11 ? year + 1 : year;
    return new Date(nextYear, nextMonth, 0);
  }

  function recalculatePodiums() {

    //UPDATE DATES
    const startWeekDate = getWeekStart(date);
    const endWeekDate = getWeekEnd(date);
    const startMonthDate = getMonthStart(date);
    const endMonthDate = getMonthEnd(date);

    let allMeals = [];

    const resultsWeek = [];
    const resultsMonth = [];
    const resultsTotal = [];
    for (const st of theClass.students) {
      allMeals = allMeals.concat(st.meals);

      //Sum points in a week
      const totalPointsWeek = st.meals.reduce((sum, m) => {
        const mealDate = new Date(m.date);
        if (mealDate >= startWeekDate && mealDate <= endWeekDate) {
          return sum + m.points;
        }
        return sum;
      }, 0);

      //Sum points in a month
      const totalPointsMonth = st.meals.reduce((sum, m) => {
        const mealDate = new Date(m.date);
        if (mealDate >= startMonthDate && mealDate <= endMonthDate) {
          return sum + m.points;
        }
        return sum;
      }, 0);

      //Sum all points
      const totalPoints = st.meals.reduce((sum, meal) => sum + meal.points, 0);

      //Add to lists
      resultsWeek.push({ _id: st._id, name: st.name, total: totalPointsWeek });
      resultsMonth.push({ _id: st._id, name: st.name, total: totalPointsMonth });
      resultsTotal.push({ _id: st._id, name: st.name, total: totalPoints });
    }

    //MEALS
    let fruitWeekL = [];
    let fruitMonthL = [];
    let fruitTotalL = [];
    for (const m of allMeals) {
      const mealDate = new Date(m.date);

      //Week
      if (mealDate >= startWeekDate && mealDate <= endWeekDate) {
        let fruit = fruitWeekL.find(fr => fr.fruit === m.fruit);
        if(!fruit) {
          fruit = {
            'fruit': m.fruit,
            'name': t(getFruitName(m.fruit)),
            'total': 0
          };
          fruitWeekL.push(fruit);
        }
        fruit.total += m.points;

      }

      //Month
      if (mealDate >= startMonthDate && mealDate <= endMonthDate) {
        let fruit = fruitMonthL.find(fr => fr.fruit === m.fruit);
        if(!fruit) {
          fruit = {
            'fruit': m.fruit,
            'name': t(getFruitName(m.fruit)),
            'total': 0
          };
          fruitMonthL.push(fruit);
        }
        fruit.total += m.points;
      }

      //Total
      let fruitTotal = fruitTotalL.find(fr => fr.fruit === m.fruit);
        if(!fruitTotal) {
          fruitTotal = {
            'fruit': m.fruit,
            'name': t(getFruitName(m.fruit)),
            'total': 0
          };
          fruitTotalL.push(fruitTotal);
        }
        fruitTotal.total += m.points;
      
    }
    // Sort lists descendant
    resultsWeek.sort((a, b) => b.total - a.total);
    resultsMonth.sort((a, b) => b.total - a.total);
    resultsTotal.sort((a, b) => b.total - a.total);
    fruitWeekL.sort((a, b) => b.total - a.total);
    fruitMonthL.sort((a, b) => b.total - a.total);
    fruitTotalL.sort((a, b) => b.total - a.total);

    setStudentWeekList(resultsWeek);
    setStudentMonthList(resultsMonth);
    setStudentTotalList(resultsTotal);
    setFruitWeekList(fruitWeekL);
    setFruitMonthList(fruitMonthL);
    setFruitTotalList(fruitTotalL);
  }

  useEffect(() => {
    recalculatePodiums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, theClass]);

  
  return (
    <div className='divStats'>

      {/* <h3>Semana del {startWeekDate.toLocaleDateString()} al {endWeekDate.toLocaleDateString()}</h3>
      <h3>Mes del {startMonthDate.toLocaleDateString()} al {endMonthDate.toLocaleDateString()}</h3> */}
      <h1>{t('stats-student')}</h1>
      <div className='divStatsStudents'>
        <Podium title={t('stats-week')} theList={studentWeekList} />
        <Podium title={t('stats-month')} theList={studentMonthList} />
        <Podium title={t('stats-total')} theList={studentTotalList} />
      </div>

      <h1>{t('stats-fruits')}</h1>
      <div className='divStatsFruits'>
        <Podium title={t('stats-week')} theList={fruitWeekList} />
        <Podium title={t('stats-month')} theList={fruitMonthList} />
        <Podium title={t('stats-total')} theList={fruitTotalList} />
      </div>
    </div>

  );
};

export default StatsView;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import './Awards.css';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addAward, removeAward, editAward } from '../utils/APIUtils';
import Modal from '../components/Modal.js';

import poster from '../assets/poster/poster3.png';
import star from '../assets/poster/star.png';

import jsPDF from 'jspdf';


function Awards({ user, awards, setAwardsData, message, setMessage, loading, setLoading, logoutMain }) {
  const { t } = useTranslation();

  const [newAwardTitle, setNewAwardTitle] = useState('');
  const [newAwardPoints, setNewAwardPoints] = useState('');

  const [showModalDeleteAward, setShowModalDeleteAward] = useState(false);
  const [showModalEditAward, setShowModalEditAward] = useState(false);

  const [selectedAward, setSelectedAward] = useState(null);

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(new Error(`Error al cargar la imagen: ${src}`));
    });
  };

  const onClickBtnGeneratePoster = async () => {
    const doc = new jsPDF();

    try {
      const imgBackground = await loadImage(poster);
      doc.addImage(imgBackground, 'PNG', 0, 0, 210, 297);

      let hightText = 60;
      let sortAwards = awards.sort((aw1, aw2) => aw1.points - aw2.points);

      for(const aw of sortAwards) {
        //Add new page
        if(hightText > 240) {
          doc.addPage();
          doc.addImage(imgBackground, 'PNG', 0, 0, 210, 297);
          hightText = 60;
        }
        //const rewimg = await loadImage(reward);
        //doc.addImage(rewimg, 'PNG', 20, hightText, 150, 15);
        doc.setFontSize(20);

        doc.addImage(star, 'PNG', 25, hightText-11, 17, 17);

        let calcX = 30;
        if(aw.points < 9) calcX = 32;
        if(aw.points > 99) calcX = 28;
        doc.text(aw.points.toString(), calcX, hightText);


        //doc.text(aw.title, 60, hightText);
        let subHeight = hightText;
        var textLines = doc.splitTextToSize(aw.title, 130);
        for(var i = 0; i < textLines.length; i++) {
          let position = ((100 - doc.getTextWidth(textLines[i])) / 2) + 60;
          doc.text(textLines[i], position, subHeight);
          subHeight += 10;
          hightText += 10;
        }
        hightText += 10;

      }

      // Descargar el PDF
      doc.save('FRUITITY-POSTER.pdf');
    } catch (error) {
      console.error('Error al generar el PDF:', error);
      alert(`Error: ${error.message}`);
    }
      
    
  };

  const onClickBtnDeleteAward = async () => {
    setLoading(true);

    const theData = await removeAward(user.token, selectedAward._id);

    if (theData && theData.ok) {
      let newAwards = awards.filter((aw) => aw._id !== selectedAward._id);
      setAwardsData(newAwards);
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }

    }
    setLoading(false);
    setSelectedAward(null);
    setShowModalDeleteAward(false);
  }

  const onClickBtnCancelDeleteAward = () => {
    setShowModalDeleteAward(false);
    setSelectedAward(null);
  }
  const onClickDeleteAward = (award) => {
    setSelectedAward(award);
    setShowModalDeleteAward(true);
  }

  const onClickEditAward = (award) => {
    setSelectedAward(award);
    setShowModalEditAward(true);
  }

  const onClickBtnAddAward = async () => {

    setLoading(true);

    const theData = await addAward(user.token, newAwardTitle, newAwardPoints);

    if (theData && theData.ok) {
      let newAwards = [...awards];
      newAwards.push(theData.data);
      setAwardsData(newAwards);
      setNewAwardPoints('');
      setNewAwardTitle('');
    } else {
      if (theData && theData.error && theData.error === 'invalid-token') {
        logoutMain();
      } else {
        setMessage({ msg: t(theData.error), type: 'ERR' });
      }
    }
    setLoading(false);
  }

  const onClickOkEditAward = async (params) => {
    setLoading(true);
    if (selectedAward) {

      const theData = await editAward(user.token, selectedAward._id, params.title, params.points);

      if (theData && theData.ok) {
        let newAwards = [...awards];
        for (let nA of newAwards) {
          if (nA._id === theData.data._id) {
            nA.title = theData.data.title;
            nA.points = theData.data.points;
          }
        }
        setAwardsData(newAwards);
      } else {
        if (theData && theData.error && theData.error === 'invalid-token') {
          logoutMain();
        } else {
          setMessage({ msg: t(theData.error), type: 'ERR' });
        }
      }
    }
    setLoading(false);
    setSelectedAward(null);
    setShowModalEditAward(false);
  }

  const onClickCancelEditAward = () => {
    setSelectedAward(null);
    setShowModalEditAward(false);
  }

  return (
    <div>
      {/******* MODAL EDIT AWARD *******/}
      {showModalEditAward && <Modal
        message={t('modal-edit-award')}
        msgOk={t('modal-btn-edit-ok')}
        msgCancel={t('modal-btn-edit-cancel')}
        agreeFunction={onClickOkEditAward}
        cancelFunction={onClickCancelEditAward}
        listFields={[
          { name: 'title', type: 'text', placeholder: t('award-name'), mandatory: true, value: (selectedAward ? selectedAward.title : '-') },
          { name: 'points', type: 'number', placeholder: t('award-points'), mandatory: true, value: (selectedAward ? selectedAward.points : '-') },
        ]}
      />}

      {/******* MODAL DELETE AWARD *******/}
      {showModalDeleteAward && <Modal
        message={t('modal-delete-award', { awardName: (selectedAward ? (selectedAward.title) : '-') })}
        msgOk={t('modal-btn-delete-ok')}
        msgCancel={t('modal-btn-delete-cancel')}
        agreeFunction={onClickBtnDeleteAward}
        cancelFunction={onClickBtnCancelDeleteAward}
      />}

      <h1>{t('awards')}</h1>
      <div className='divAwards'>
        {(!awards || awards.length === 0) &&
          <p className='pNoAwards'>{t('no-awards')}</p>
        }
        {awards && awards.length > 0 &&
          <>
            <div className='divGeneratePoster'>
              <button onClick={() => onClickBtnGeneratePoster()}>{t('btn-generate-poster')}</button>
            </div>
            <table className='tableAwards'>
              <thead>
                <tr className='trTitle'>
                  <td className='tdTitleTitle'><h3>{t('award-name')}</h3></td>
                  <td className='tdTitlePoints'><h3>{t('award-points')}</h3></td>
                  <td className='tdEdit'></td>
                  <td className='tdDelete'></td>
                </tr>
              </thead>
              <tbody>
                {awards && awards.map((aw) => (
                  <tr className='trAward' key={aw._id}>
                    <td className='tdAwardTitle'><p>{aw.title}</p></td>
                    <td className='tdAwardPoints'><p>{aw.points}</p></td>
                    <td className='tdAwardEdit'>
                      <FontAwesomeIcon className='awardIconEdit' icon={faEdit} onClick={(e) => onClickEditAward(aw)} />
                    </td>
                    <td className='tdAwardDelete'>
                      <FontAwesomeIcon className='awardIconDelete' icon={faTrash} onClick={(e) => onClickDeleteAward(aw)} />
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </>}

      </div>

      <h1>{t('new-awards')}</h1>
      <div className='divNewAwardInputs'>
        <input
          type={"text"}
          placeholder={t('award-name')}
          value={newAwardTitle}
          onChange={(e) => setNewAwardTitle(e.target.value)}
        />
        <input
          type={"number"}
          placeholder={t('award-points')}
          value={newAwardPoints}
          onChange={(e) => setNewAwardPoints(e.target.value)} />
      </div>
      <div className='divNewAwardBtn'>
        <button onClick={() => onClickBtnAddAward()}>{t('award-add-btn')}</button>
      </div>




    </div>
  );
};

export default Awards;

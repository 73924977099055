import React from 'react';

import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
import { getFruitImage } from '../../utils/FruitImgUtil';
import { getMySQLDateFromJSDate } from '../../utils/DateUtils';

import { addMealToUser } from '../../utils/APIUtils.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './StudentBox.css';
import { getAvatarImage } from '../../utils/AvatarUtil.js';

function StudentBox({ date, student, setSelectedStudent, loading, setLoading,
  message, setMessage, theClass, classesData, setClassesData, selectedFruit,
  setSelectedFruit, selectedAward, setSelectedAward, setShowViewStudent, user, deleteMeal, setSelectedMeal,
  setShowModalDeleteMeal, setShowModalAddAward, classView, awardsView, logoutMain }) {

  const { t } = useTranslation();



  //const { cl_id, st_id } = useParams();

  // Get meals of the day
  const getMealsSameDay = (meals) => {
    return meals.filter((meal) => isSameDay(date, meal.date));
  }

  // When user click on hover icon on meal in computer mode
  const onClickDeleteMeal = (event, student, meal) => {
    event.stopPropagation();
    deleteMeal(student, meal);
  }

  // When user click on meal we must check if it is in mobile mode
  const onClickMealImg = async (event, student, meal) => {
    event.stopPropagation();

    if (window.screen.width < 768) {
      setSelectedFruit(null);
      setSelectedMeal(meal);
      setSelectedStudent(student);
      setShowModalDeleteMeal(true);
    }
  }

  // WHen click on button view student
  const onClickViewStudent = (event, student) => {
    event.stopPropagation();
    setSelectedStudent(student);
    setShowViewStudent(true);
  }


  // When drop on student emulate onClickStudent click
  const onDropStudent = (event, st) => {
    event.preventDefault();
    setSelectedStudent(st);
    onClickStudent(st);
  };

  // When you click on student box, 
  //if is classview and some fruit is selected it must add meal to student
  //if is awardview and some award is selected show modal
  const onClickStudent = async (student) => {
    if (selectedFruit && classView) {
      setLoading(true);
      const theData = await addMealToUser(user.token, theClass._id, getMySQLDateFromJSDate(date), student._id, selectedFruit._id, 1);

      if (theData && theData.ok) {
        classesData = [...classesData.filter((c) => c._id !== theClass._id)];
        classesData.push(theData.data);
        setClassesData(classesData);
      } else {
        if (theData && theData.error && theData.error === 'invalid-token') {
          logoutMain();
        } else {
          setMessage({ msg: t(theData.error), type: 'ERR' });
        }
      }
      setLoading(false);
    }
    if (awardsView && selectedAward) {
      let awardPoints = student.awards.reduce((sum, aw) => sum + aw.points, 0);
      let totalPoints = student.meals.reduce((sum, m) => sum + m.points, 0);
      if (totalPoints - awardPoints < selectedAward.points) {
        setMessage({ msg: t('no-enough-points'), type: 'ERR' });
      } else {
        setSelectedStudent(student);
        setShowModalAddAward(true);
      }
    }
  }

  // Check if two dates are the same using day, month and year
  const isSameDay = (dateCheck, stringDate) => {
    let newDate = new Date(stringDate);
    return dateCheck.getFullYear() === newDate.getFullYear() &&
      dateCheck.getMonth() === newDate.getMonth() &&
      dateCheck.getDate() === newDate.getDate();
  }

  return (
    < div
      id={'student' + student._id}
      onClick={() => onClickStudent(student)}
      key={student._id}
      draggable
      onDrop={(event) => onDropStudent(event, student)}
      onDragOver={(event) => event.preventDefault()}
      className="divStudent"
    >

      <div className="divStudentFirstRow">
        <img className="imagAvatarStudentBox" onClick={(e) => onClickViewStudent(e, student)} src={getAvatarImage(student.avatar)} alt="avatar" />
        <h3 className="studentName">{student.name}</h3>
      </div>

      {/* POINTS GENERAL */}
      {awardsView && <div className="divStudentPoints">
        {/* OLD VERSION WITH TOTALS AND CURRENT */}
        {/*
        <div>
          <h3>{student.meals ? student.meals.reduce((sum, meal) => sum + meal.points, 0) : 0}</h3>
          <p>{t('total-points')}</p>
        </div>
        <div>
          <h3>{student.awards && student.meals ? (student.meals.reduce((sum, meal) => sum + meal.points, 0) - student.awards.reduce((sum, aw) => sum + aw.points, 0)) : 0}</h3>
          <p>{t('current-points')}</p>
        </div>
        */}

        {/* NEW VERSION WITH CURRENT */}

        <div className="divStudentTotalPoins">
          <div>
            <h3>{student.awards && student.meals ? (student.meals.reduce((sum, meal) => sum + meal.points, 0) - student.awards.reduce((sum, aw) => sum + aw.points, 0)) : 0}</h3>
          </div>
        </div>

      </div>}

      {/* POINTS */}
      {classView &&
        <div className="divStudentTotalPoins">
          <div>
            {/* Current */}
            <h3>{student.awards && student.meals ? (student.meals.reduce((sum, meal) => sum + meal.points, 0) - student.awards.reduce((sum, aw) => sum + aw.points, 0)) : 0}</h3>
            {/* Total */}
            {/* <h3>{student.meals ? student.meals.reduce((sum, meal) => sum + meal.points, 0) : 0}</h3> */ }
          </div>
        </div>
      }

      {/* MEAL LIST */}
      {classView && <div className="divStudentMeals"
      >
        {student.meals && getMealsSameDay(student.meals).map((m) => (

          /* MEAL VIEW */
          <div id={'meal' + m._id} className="divMeal" key={m._id}>
            <img src={getFruitImage(m.fruit)} onClick={(e) => onClickMealImg(e, student, m)} alt={"fruit"} />
            <FontAwesomeIcon onClick={(e) => onClickDeleteMeal(e, student, m)} className="btnDeleteMeal" icon={faTrash} />
          </div>
        ))}
        {
          (!student.meals || getMealsSameDay(student.meals).length === 0)
          /* REMOVED 
          &&
          <p>{t('no-fruits')}</p>
        */ 
        }
      </div>}
    </div>
  );
};

export default StudentBox;

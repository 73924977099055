import React, { createContext, useContext, useEffect, useState } from 'react';
import { getUserLogin, getUserLoginGoogle, loginUserWithPin } from '../utils/APIUtils';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const cachedUser = localStorage.getItem('user');
    if (cachedUser !== null) {
      setUser(JSON.parse(cachedUser));
    }
  }, []);

  const getDataUserLogin = async (userData) => {
    const theData = await getUserLogin(userData.email, userData.password);
    return theData;
  }

  const getDataUserLoginGoogle = async (userGoogleAccessToken, userGoogleName, userGoogleEmail) => {
    const theData = await getUserLoginGoogle(userGoogleAccessToken, userGoogleName, userGoogleEmail);
    return theData;
  }

  const login = async (userData) => {
    const theData = await getDataUserLogin(userData);
    if (theData.ok) {
      userData = theData.data;
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));

    }
    return theData;
  };

  const loginPin = async (email, pin) => {
    const theData = await loginUserWithPin(email, pin);
    if (theData.ok) {
      let userData = theData.data;
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));

    }
    return theData;
  }

  const loginGoogle = async (userGoogle) => {
    const theData = await getDataUserLoginGoogle(userGoogle.accessToken, userGoogle.displayName, userGoogle.email);
    if (theData.ok) {
      let userData = theData.data;
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData));

    }
    return theData;
  };

  const editUserAuth = async (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  }

  const logout = () => {
    localStorage.setItem('user', null);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, editUserAuth, login, loginPin, logout, setUser, loginGoogle }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
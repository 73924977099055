import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../language/i18n.js';

import './User.css';
import getLangImg from '../utils/LangUtil.js';
import { editUser } from '../utils/APIUtils.js';
import { useAuth } from '../context/AuthContext.js';
function User({ user, message, setMessage, loading, setLoading, logoutMain }) {
  const { t, i18n } = useTranslation();

  const { editUserAuth } = useAuth();


  const [editing, setEditing] = useState(false);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [language, setLanguage] = useState('');

  const [errors, setErrors] = useState({
    errorName: false,
    errorSurname: false,
    errorPassword: false,
    errorRepassword: false
  });

  const onClickEditBtn = () => {
    setName(user.name);
    setSurname(user.surname);
    setPassword('');
    setRepassword('');
    setLanguage(user.language ? user.language : 'es');
    setEditing(true);
  }

  const checkCorrectData = () => {
    const newErrors = { ...errors };
    let correctData = true;

    if (name.length > 255) {
      newErrors.errorName = true;
      correctData = false;
    }

    if (surname.length > 255) {
      newErrors.errorSurname = true;
      correctData = false;
    }

    if (password !== '') {
      const regexPwd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
      if (password.length < 8 || !regexPwd.test(password)) {
        newErrors.errorPassword = true;
        correctData = false;
      }

      if (password !== repassword) {
        newErrors.errorRepassword = true;
        correctData = false;
      }
    }

    if (!correctData) {
      setErrors(newErrors);
    } else {
      setErrors({
        errorName: false,
        errorSurname: false,
        errorPassword: false,
        errorRepassword: false
      });
    }
    return correctData;
  }


  const onClickSaveBtn = async () => {
    setLoading(true);

    let correctData = checkCorrectData();

    if (correctData) {

      let isNewPassword = password !== '';

      const theData = await editUser(user.token, name, surname, isNewPassword, password, language);

      if (theData && theData.ok) {
        editUserAuth(theData.data)
        let lang = theData.data.language ? theData.data.language : 'es';
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', JSON.stringify(lang));
      } else {
        if (theData && theData.error && theData.error === 'invalid-token') {
          logoutMain();
        } else {
          setMessage({ msg: t(theData.error), type: 'ERR' });
        }
      }
      setEditing(false);
    }

    setLoading(false);
  }

  return (
    <div>
      <h1>{t('user')}</h1>
      <div className='divUserFields'>
        <div className='divUserField divUserName'>
          <p>{t('name')}</p>
          {!editing && <h3>{user ? user.name : '-'}</h3>}
          {editing &&
              <input name="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
          }
        </div>
          {errors && errors.errorName && <p className='updateUserError'>{t('wrongSignupName')}</p>}

        <div className='divUserField divUserSurame'>
          <p>{t('surname')}</p>
          {!editing && <h3>{user ? user.surname : '-'}</h3>}
          {editing &&
              <input name="surname" type="text" value={surname} onChange={(e) => setSurname(e.target.value)} />
          }
        </div>
          {errors && errors.errorSurname && <p className='updateUserError'>{t('wrongSignupSurname')}</p>}

        <div className='divUserField divUserEmail'>
          <p>{t('email')}</p>
          <h3>{user ? user.email : '-'}</h3>
        </div>

        {!editing &&
          <div>
            <div className='divUserField divUserPassword'>
              <p>{t('password')}</p>
              <h3>****</h3>
            </div>
          </div>

        }

        {editing &&
          <>
            <div className='divUserField divUserPassword'>
              <p>{t('password')}</p>
              <input name={"password"} type={"password"} value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            {errors && errors.errorPassword && <p className='updateUserError'>{t('wrongSignupPassword')}</p>}

            <div className='divUserField divUserRepassword'>
              <p>{t('repassword')}</p>
              <input name={"repassword"} type={"password"} value={repassword} onChange={(e) => setRepassword(e.target.value)} />
            </div>
            {errors && errors.errorRepassword && <p className='updateUserError'>{t('wrongSignupRepassword')}</p>}
          </>
        }


        <div className='divUserField divUserLanguage'>
          <p>{t('language')}</p>
          {!editing && <img alt="lang" className='imgUserLang' src={getLangImg(user.language)} />}
          {editing &&
            <div>
              <img alt="es" onClick={() => setLanguage('es')} className={`imgUserLang ${language === 'es' && 'imgUserLangSelected'}`} src={getLangImg('es')} />
              <img alt="ca" onClick={() => setLanguage('ca')} className={`imgUserLang ${language === 'ca' && 'imgUserLangSelected'}`} src={getLangImg('ca')} />
              <img alt="en" onClick={() => setLanguage('en')} className={`imgUserLang ${language === 'en' && 'imgUserLangSelected'}`} src={getLangImg('en')} />
            </div>
          }
        </div>

        <div className='divUserBtns'>
          {!editing && <button onClick={onClickEditBtn}>{t('edit')}</button>}
          {editing && <button onClick={onClickSaveBtn}>{t('save')}</button>}
        </div>


      </div>
    </div>
  );
};

export default User;

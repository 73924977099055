import React from 'react';
import './Loading.css';
import fruitityIcon from '../assets/fruitity-icon.png';

function Loading() {
 
  return (
        <div className='divLoading'>
          <div>
            <img alt="fruitityicon" src={fruitityIcon}/>
          </div>
        </div>
  );
};

export default Loading;

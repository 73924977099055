import React from 'react';
import './Message.css';

function Message({ message, setMessage }) {

    
    return (
        <div 
            className={`divMessage 
            ${((!message || !message.type) && 'divMessageOK') ||
                (message.type === 'WARN' && 'divMessageWarning') ||
                (message.type === 'ERR' && 'divMessageError') ||
                (message.type === 'OK' && 'divMessageOK')}
            `}>
            <p>{message ? message.msg : ''}</p>
            <button onClick={() => setMessage(null)}>X</button>
        </div>
    );
};

export default Message;

import React from 'react';
import './Calendar.css';

import { getMySQLDateFromJSDate } from '../utils/DateUtils';

function Calendar({ date, setDate }) {
 
  const handleRemoveDay = () => {
    setDate(new Date(date.getTime() - 86400000));
  };

  const handleAddDay = () => {
    setDate(new Date(date.getTime() + 86400000));
  };

  const handleSetDate = (e) => {
    setDate(new Date(e.target.value));
  }

  return (
        <div className='divCalendar'>
          <button onClick={handleRemoveDay}>&lt;</button>
          <input type="date" value={getMySQLDateFromJSDate(date)} onChange={(e) => handleSetDate(e)} />
          <button onClick={handleAddDay}>&gt;</button>
        </div>
  );
};

export default Calendar;

import React from 'react';
import './Cookies.css';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';


function Cookies({ setShowCookies, acceptCookies, logoutMain }) {
    const { t } = useTranslation();

    const yesCookiesFunction = () => {
        acceptCookies();
    };
    const noCookiesFunction = () => {
        setShowCookies(false);
      logoutMain();
    };

    return (
        <div className='divCookies'>
            {t('cookies-message')}
            <button className='buttonInverse' onClick={yesCookiesFunction}>{t('cookies-yes')}</button>
            <button className='buttonInverse' onClick={noCookiesFunction}>{t('cookies-no')}</button>
        </div>
    );
};

export default Cookies;
